import classNames from 'classnames';
import CopyButton from 'modules/common/components/CopyButton';
import EmptyState from 'modules/common/components/EmptyState';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import React from 'react';
import { SiWhatsapp as IconWhatsApp } from 'react-icons/si';

type AuthorizationScreenProps = {
  type: string;
} & React.HTMLAttributes<HTMLDivElement>;

const AuthorizationScreen: React.FC<AuthorizationScreenProps> = ({ children, type, className, ...props }) => {
  const { emailHello, whatsappDorothee, whatsappMartin, calendarUrl } = usePublicRuntimeConfig();
  let title = "Vous n'êtes pas autorisé";
  let subtitle = (
    <>
      Vous n&apos;avez pas le droit d&apos;effectuer cette action.
      <br />
      <br />
      contactez nous sur WhatsApp pour toute question
    </>
  );
  let cta = 'Contactez-nous sur WhatsApp';
  let onClick = () => {
    window.open(whatsappDorothee, '_blank');
  };
  let icon = IconWhatsApp;

  if (type === 'member') {
    title = "Ton profil n'est pas encore validé";
    subtitle = (
      <>
        <br />
        La confiance au sein de notre communauté est notre priorité.
        <br />
        <br />
        Afin de mieux vous connaître, et de nous assurer de l’alignement de nos valeurs, nous prenons le temps d’un entretien individuel avec chacun
        d’entre vous.
        <br />
        <br />- Si nous n’avons pas encore eu l’occasion d’échanger :{' '}
        <a className="link link-primary font-bold" target="_blank" rel="noopener noreferrer" href={calendarUrl}>
          réservez un créneau
        </a>
        <br />
        <br />- Si notre Visio a déjà eu lieu, patience, <strong>Vous serez informé par email</strong> dès validation de ton profil.
        <br />
        <br />
        <hr />
        <br />
        Afin d&apos;être sûr de recevoir cet email et qu&apos;il ne finisse pas dans vos SPAMS, <strong>veille à ajouter l&apos;email</strong> suivant
        à ta liste de contacts
        <br />
        <br />
        <strong>
          <CopyButton value={emailHello}>{emailHello}</CopyButton>
        </strong>
        <br />
        <br />
        <hr />
        <br />
        <a className="link" href={whatsappDorothee} target="_blank" rel="noopener noreferrer">
          Dorothée
        </a>{' '}
        et{' '}
        <a className="link" href={whatsappMartin} target="_blank" rel="noopener noreferrer">
          Martin
        </a>
        <br />
        <br />
        Et si vous êtes pressé
      </>
    );
  }

  return (
    <div className={classNames('p-5 sm:p-24 border border-gray-100 shadow-sm', className)} {...props}>
      <EmptyState title={title} subtitle={subtitle} cta={cta} onClick={onClick} icon={icon} />
    </div>
  );
};

export default AuthorizationScreen;
